<template>
  <div v-if="isShow" class="headerAdv">
    <!-- 5宫格广告 -->
    <div class="fiveGrid" v-if="showAds.length">
      <div
        class="rectAdvBox"
        @click="jumpAdv(item)"
        v-for="item in showAds"
        :key="item.id"
      >
        <lazyImg class="rectAdvIcon" :src="item.pictureUrl" />
        <div class="rectName">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImg from "@/components/LazyImg/index.vue";
import { jumpAdv } from "@/utils/getAdv.js";
import { getAdItem, AdType } from "@/utils/getAdv.js";

const getLoopArray = (arr, index, count) => {
  const length = arr.length;
  if (length < 1) return [];
  const outputArr = [];
  let startIndex = (index * count) % length;
  const endIndex = startIndex + count;
  while (startIndex < endIndex) {
    outputArr.push(arr[startIndex % length]);
    startIndex++;
  }
  return outputArr;
};

export default {
  components: { LazyImg },
  props: {
    advType: {
      type: Number,
      default: AdType.topicAdv,
    },
    showCount: {
      type: Number,
      default: 5,
    },
    interval: {
      type: Number,
      default: 2,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ads() {
      console.log(getAdItem(this.advType) || [], "--------------");
      return getAdItem(this.advType) || [];
    },
    isShow() {
      // 第0个开始展示
      // if (!this.index) return true
      // if (this.interval - 1 <= 0) return true
      // return this.index % this.interval === 0
      // 第interval-1开始展示
      if (this.interval <= 0 || !this.index) return false;
      if (this.interval - 1 === 0) return true;
      return this.index % this.interval === this.interval - 1;
    },
    showAds() {
      return getLoopArray(
        this.ads,
        (this.index - 1) / this.interval,
        this.showCount
      );
    },
  },
  methods: {
    jumpAdv,
  },
};
</script>
<style scoped lang="scss">
.headerAdv {
  .fiveGrid {
    margin: 10px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    box-sizing: border-box;

    .rectAdvBox {
      width: 60px;

      .rectAdvIcon {
        height: 60px;
        width: 60px;
        border-radius: 14.6px;
        overflow: hidden;
      }

      .rectName {
        font-size: 12px;
        color: #666;
        margin-top: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}
</style>
